import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";

Sentry.init({
  dsn: 'https://2bf4f85a52c4cc18dae904f0eb53392c@o4505920340426752.ingest.us.sentry.io/4507050311155712',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],
  enableTracing: process.env.NODE_ENV === "production",
  enabled: process.env.NODE_ENV === "production",
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
